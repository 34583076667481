<template>
    <div class="wrapper">
        <div v-if="!success" class="form_box">
<!--            <img class="top" src="https://static.yihu365.cn/img/h5Img/assets/img/form_top.png" alt="">-->
            <div class="content">
                <h3>个人信息：</h3>
                <div class="message_one">
                    <van-cell is-link title="您的性别：" @click="showSex = true" :value="sex ? sex : '请选择性别'"/>
                    <van-action-sheet v-model:show="showSex" :actions="sexs" @select="onSelectSex"/>
                    <van-cell is-link title="您的年龄：" @click="showAge = true" :value="age ? age : '请选择年龄'"/>
                    <van-action-sheet v-model:show="showAge" :actions="ages" @select="onSelectAge"/>
                    <van-cell is-link title="您的职业：" @click="showOccup = true" :value="occup ? occup : '请选择职业'"/>
                    <van-action-sheet v-model:show="showOccup" :actions="occupation" @select="onSelectOcc"/>
                </div>
                <h3>快递收货信息：</h3>
                <div class="message_two">
                    <van-field v-model="consignee" label-align="right" label="收货人：" placeholder="请输入收货人姓名"/>
                    <van-field v-model="mobile" label-align="right" label="联系电话：" placeholder="请输入联系电话"/>
                    <van-field v-model="consigneeAddress" label-align="right" label="收件地址：" placeholder="请输入收货人地址"/>
                </div>
                <button class="btn" @click="setAllData">提交信息</button>
            </div>
        </div>
        <img v-if="success" class="ok_box" src="https://static.yihu365.cn/img/h5Img/assets/img/ok.png" alt="">
    </div>
</template>

<script>
    import {ref, watch} from 'vue'
    import {useRouter, useRoute} from 'vue-router'
    import {Toast, ActionSheet, Field, CellGroup} from 'vant'
    import loginApi from '@axios/login'
    export default {
        name: "formContent",
        setup() {
            const route = useRoute()
            let tel_reg = /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/;
            const showAge = ref(false);
            const showSex = ref(false);
            const showOccup = ref(false);
            const value = ref('');
            const sex = ref('');
            const age = ref('');
            const occup = ref('');
            const consignee = ref('');
            const mobile = ref('');
            const consigneeAddress = ref('');
            const success = ref(false);

            const sexs = [
                {name: '男'},
                {name: '女'},
            ];
            const ages = [
                {name: '20岁以下'},
                {name: '21-45周岁'},
                {name: '46-60周岁'},
                {name: '61周岁以上'},
            ];
            const occupation = [
                {name: '运输配送'},
                {name: '制造业'},
                {name: '维修安装'},
                {name: '互联网'},
                {name: '商超零食'},
                {name: '教育培训'},
                {name: '汽车服务'},
                {name: '房产服务'},
                {name: '美业理疗'},
                {name: '家族服务'},
                {name: '文化娱乐'},
                {name: '摄影摄像'},
                {name: '运动健身'},
                {name: '旅游服务'},
                {name: '其他'},
            ];
            const onSelectSex = (item, index) => {
                // 默认情况下点击选项时不会自动收起
                // 可以通过 close-on-click-action 属性开启自动收起
                sex.value = item.name;
                showSex.value = false;
                // Toast(item.name);
            };
            const onSelectAge = (item, index) => {
                // 默认情况下点击选项时不会自动收起
                // 可以通过 close-on-click-action 属性开启自动收起
                age.value = item.name;
                showAge.value = false;

            };
            const onSelectOcc = (item, index) => {
                // 默认情况下点击选项时不会自动收起
                // 可以通过 close-on-click-action 属性开启自动收起
                occup.value = item.name;
                showOccup.value = false;

            };
            const setAllData = () => {
                if (consignee.value && mobile.value && consigneeAddress.value) {
                    if (!tel_reg.test(mobile.value)) {
                        Toast('请输入正确手机号');
                        return;
                    }

                    let formData = new FormData();
                    formData.append('data', JSON.stringify({
                        age:age.value,
                        sex:sex.value,
                        occupation:occup.value,
                        consignee:consignee.value,
                        mobile:mobile.value,
                        consigneeAddress:consigneeAddress.value,
                        userId:route.query.userId
                    }))
                    //请求接口
                    loginApi.setFormData(formData).then((res) => {
                       console.log(res)
                        if(res.code == '0000'){
                            success.value =true;
                        }
                    })

                } else {
                    Toast("请补全收货信息")
                }
            }
            watch([() => consignee], ([newc], [oldc]) => {
                console.log('ok')
            });
            return {
                showOccup,
                showAge,
                showSex,
                sexs,
                ages,
                occupation,
                value,
                onSelectOcc,
                onSelectSex,
                onSelectAge,
                sex,
                age,
                occup,
                consignee,
                mobile,
                consigneeAddress,
                setAllData,
                success
            };
        }
    }
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
    .wrapper {
        .ok_box{
            position: absolute;
            width: 100%;
        }
        position: relative;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        background: url("https://static.yihu365.cn/img/h5Img/assets/img/bgform.png") no-repeat;
        background-size: 100% 100%;

        .top {
            position: absolute;
            width: 100%;
        }

        .content {
            h3 {
                font-size: 28px;
                margin: 20px 0;
                color: #ffffff;
            }

            box-sizing: border-box;
            padding: 0 60px;
            width: 100%;
            margin-bottom: 70px;
            min-height: 700px;
            position: absolute;
            left: 0;
            bottom: 0;

            .message_one {
                color: #666666;
                border-radius: 20px;
                background: white;
                min-height: 228px;
                padding: 10px 0
            }

            .message_two {
                border-radius: 20px;
                background: white;
                min-height: 228px;
                padding: 10px 0;
            }

            .btn {
                display: block;
                margin: 40px auto;
                border: none;
                width: 270px;
                height: 80px;
                font-size: 36px;
                /*color: #999999;*/
                /*background: #ffffff;*/
                color:#ffffff;
                background: #00A3FF;
                border-radius: 40px;
            }

            ::v-deep(.van-cell) {
                color: #666666;
                border-radius: 20px;
                padding-top: 18px;
                padding-bottom: 8px;
            }

            ::v-deep(.van-action-sheet__item) {
                color: #666666;
            }

            ::v-deep(.van-field__label) {
                width: 145px;
                margin-right: 10px;
            }

        }

        .ok_box {

        }
    }
</style>
